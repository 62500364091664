import axios from "axios";
import {
  API_ROOT_PROD,
  API_ROOT_TEST,
  CUSTOM_API_ROOT_PROD,
  CUSTOM_API_ROOT_TEST,
} from "../config";

export default axios.create({
  baseURL: window.location.hostname.includes("istyle-test")
    ? API_ROOT_TEST
    : API_ROOT_PROD,
  timeout: 60000,
  headers: {
    Accept: "application/json",
    "Cache-Control": "no-cache",
    "Content-Type": "application/json",
    "B1S-CaseInsensitive": true,
  },
  transformResponse: (data) => {
    return data;
  },
  withCredentials: true,
});

export const customFuncsApi = axios.create({
  baseURL: window.location.hostname.includes("istyle-test")
    ? CUSTOM_API_ROOT_TEST
    : CUSTOM_API_ROOT_PROD,
  timeout: 60000,
  headers: {
    Accept: "application/json",
    "Cache-Control": "no-cache",
    "Content-Type": "application/json",
    "B1S-CaseInsensitive": true,
  },
  withCredentials: true,
});
