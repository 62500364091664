import React, { useRef } from "react";
import { ErrorModal } from "../../components/Modal";
import { useLocation } from "react-router-dom";
import Button from "../../components/Button";
import Layout from "../../components/Layout";
import DownloadStyle from "./DownloadStyle";
import html2PDF from "jspdf-html2canvas";
import { get } from "lodash";
import moment from "moment/moment";

const Download = () => {
  const location = useLocation();
  const errorRef = useRef();
  const dataLocation = get(location, "state.data", {});

  const download = () => {
    let element = document.getElementsByClassName("container");

    html2PDF(element, {
      margin: 10,
      output: `${get(location, "state.user.Invoices.CardName", "shartnoma")}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, logging: true, dpi: 192, letterRendering: true },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    });
  };

  //

  return (
    <Layout>
      <DownloadStyle>
        <div className="flexEnd">
          <Button
            onClick={download}
            btnStyle={{ marginBottom: 10, width: 225 }}
          >
            Загрузить договор
          </Button>
        </div>
        <div className="container">
          <div className="halfCardMini">
            <p className="topTitle">
              ДОГОВОР КУПЛИ-ПРОДАЖИ НА ОСНОВЕ РАССРОЧКЕ{" "}
              {/*{get(dataLocation, "DocEntry", 0)}*/}
            </p>
            <div className="between">
              <p>Ташкент </p>
              <p>{moment(new Date()).format("MM.DD.YYYY")}й.</p>
            </div>
            <p>
              ООО «iSTYLE Plus» , далее именуемое «Продавец», в лице директора
              Шокиров Шохрух Зариф Ўғли, действующего на основе устава , с одной
              стороны , и гражданин Республики Узбекистан
              (ФИО)___________________________________________, далее именуемый
              «Покупатель», с другой стороны, заключили настоящий договор о
              нижеследующем:
            </p>
            <>
              <p className="topTitle">1. ПРЕДМЕТ ДОГОВОРА</p>
              <p>
                1.1. Продавец обязуется передать в собственность Покупателю
                товар в ассортименте и в количестве, установленных Договором, а
                Покупатель обязуется принять этот товар и оплатить стоимость
                товара на условиях настоящего Договора.
              </p>
              <p>1.2. Информация о продаваемом товаре: </p>
              <div className="containerTable">
                <table>
                  <thead>
                    <tr>
                      <th>№</th>
                      <th>
                        Наименование
                        <br />
                        товара
                      </th>
                      <th>IMEI код</th>
                      <th>Кол-во</th>
                      <th>Стоимость</th>
                      <th>Всего</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>
                        {get(
                          dataLocation,
                          "DocumentLines[0].ItemDescription",
                          "",
                        )}
                      </td>
                      <td>
                        {get(
                          dataLocation,
                          "DocumentLines[0].SerialNumbers[0].InternalSerialNumber",
                          0,
                        )}
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <b>Итого:</b>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
            <>
              <p className="topTitle">2. СТОИМОСТЬ ДОГОВОРА И ПОРЯДОК ОПЛАТЫ</p>
              <p>
                2.1. По настоящему договору оплата производится в следующем
                порядке:
              </p>
              <p>2.2. Общая стоимость товара составляет __________ USD.</p>
              <p>
                2.3. Покупатель обязуется оплатить предоплату в размере ____ USD
                при подписании данного договора
              </p>
              <p>
                2.4.Оставшуюся стоимость товара __________ USD Покупатель
                обязуется оплатить в течении ______ месяцев по __________ USD.
              </p>
              <p>
                2.5 Срок ежемесячной оплаты Товара устанавливается на день его
                приобретения.
              </p>
              <p>
                2.6. Покупатель осуществляет ежемесячную оплату согласно
                приложению №1 настоящего Договора.
              </p>
              <p>
                2.7. Оплата производится согласно курсу иностранной валюты
                ЦентроБанка РУз в день оплаты товара.
              </p>
            </>

            {/* thrid */}
            <>
              <p className="topTitle">3. ПОРЯДОК ПЕРЕДАЧИ И ПРИНЯТИИ ТОВАРА</p>
              <p>
                3.1.Продавец обязуется передать товар в течении 1 дня с момента
                осуществления предоплаты указанной в пункте 2.3 данного
                Договора.
              </p>
              <p>
                3.2. Продавец доставляет товар и входящие в него документы
                покупателю в количестве и качестве, соответствующих условиям
                договора, в таре уровня, исключающего возможность повреждения,
                поломки или уничтожения при транспортировке.
              </p>
              <p>
                3.3. Продавец передает коробку товара после полной оплаты
                стоимости товара указанной в пункте 2.2 данного договора.
              </p>
              <p>
                3.4. Передача товара осуществляется по данному адресу: Г.
                Ташкент, Юнусабадский район, Центр-6, 111д, 2-квартира.
              </p>
              <p>
                3.5. Покупатель обязуется проверить и товар на предмет
                комплектности, пригодности и соответсвии его с договором и
                принять его.
              </p>
            </>
            {/* fourth */}
            <p className="topTitle">4. ПРАВА И ОБЯЗЯННОСТИ СТОРОН</p>
            <p>
              <b>4.1. Права продавца:</b>
            </p>
            <p>
              - требовать своевременную оплату за проданный товар указанный в
              договоре.
              <br />- отправлять SMS-рассылки с напоминанием об оплате товара.
            </p>
            <p>
              <b>4.2. Обязянности продавца:</b>
            </p>
            <p>
              - передать товар во владение покупателя;
              <br /> - передать товар, указанный в договоре;
              <br /> - передать товар в количестве, указанном в договоре;
              <br /> - передать товар надлежащего качества.
            </p>
            <p>
              <b>4.3. Права покупателя::</b>
            </p>
            <p>
              - требовать о передаче купленного товара во владение;
              <br /> - требовать о передаче указанного количества товара во
              владение;
              <br /> - требовать о передаче товара с указанной в настоящем
              договоре модификацией.
            </p>
          </div>
        </div>

        {/* secend big Card */}
        <div className="container">
          <div className="halfCardMini">
            <p>
              <b>4.4. Обязанности покупателя:</b>
            </p>
            <p>
              -своевременно оплачивать товар в указанном порядке.
              <br /> - принять товар после предоплаты указанной в пункте 2.3
              данного договора.
              <br /> - следовать всем условиям данного договора;
              <br /> - конпенсировать нанесенный продавцу ущерб.
            </p>
            {/* fifth */}
            <p className="topTitle">5. 5. ГАРАНТИЯ</p>
            <p>
              5.1. Продукция продаваемая в магазине iSTYLE PLUS гарантирована и
              предоставляется своевременный гарантированный сервис.
            </p>
            <p>
              5.2. Гарантия на реализуемый по договору товар подразумевает
              технический и программный ремонт, осуществляемый в официальных
              сервисных центрах, обслуживающих город Ташкент.
            </p>
            <p>
              5.3. Условия гарантии:
              <br /> - гарантия для новых устройств 3(три) месяца.
              <br /> - для использованных устройств гарантия 1(один) месяц.
              <br /> - Гарантия распространяется на дефекты, возникшие по вине
              производителя.
              <br /> - если в новом устройстве в течение 3 дней будет обнаружен
              производственный дефект, устройство будет заменено на новое. .
              <br />- гарантийный срок отсчитывается со дня передачи товара
              Покупателю.
              <br /> - гарантия не распространяется на механические повреждения:
              (дефекты в корпусе,сломанный дисплей, согнутый корпус, вода и
              влага внутри устройства.)
              <br /> - Если покупатель использует товар не по назначению или
              использует его вопреки инструкции производителя, продавец не
              принимает на себя никаких гарантийных обязательств перед
              покупателем.
            </p>
            {/* sixth */}
            <p className="topTitle">6. ОТВЕТСТВЕННОСТЬ СТОРОН</p>
            <p>
              6.1. Покупатель передает продавцу приобретенное устройство в залог
              через его коробку и систему защиты (установка системы защиты
              iCloud на устройствах Apple).
            </p>
            <p>
              6.2. Система безопасности iCloud, которая устанавливается на
              устройства Apple, продавцом, направлена на выявление
              недобросовестных покупателей и их блокировку.
            </p>
            <p>
              6.3. Система безопасности iCloud, установленная Продавцом на
              устройствах Apple, не дает Продавцу права доступа к персональным
              данным Покупателя, а Продавец гарантирует абсолютную
              неприкосновенность личных данных Покупателя.
            </p>
            <p>
              6.4. Если покупатель не производит своевременную оплату по
              договору, продавец может принять следующие меры:
            </p>
            <p>
              6.5. Продавец списывает просроченные платежи по договору со всех
              существующих банковских карт Покупателя, в том числе валютных
              (Visa, UnionPay, МИР, Master Card) без акцепта в счет возникшей по
              договору задолженности. При этом, подписывая настоящий договор,
              Покупатель дает полное и безоговорочное согласие на снятие
              денежных средств с банковской карты (лицевого счета) для покрытия
              задолженности перед Продавцом, без решения Покупателя, в случае
              просрочки платежа.
            </p>
            <p>
              6.6. Покупатель (при условии, что к нему подключена услуга
              SMS-информирования) будет проинформирован о снятии денежных
              средств с банковской карты (лицевого счета) посредством SMS.
            </p>
            <p>
              6.7. Если Покупатель просрочит уплату ежемесячных платежей на 10
              дней без письменного уведомления Продавца, устройство будет
              заблокировано.
            </p>
            <p>
              6.8. Если Покупатель не произведет оплату в течение 10 дней после
              уведомления, устройство будет заблокировано удаленно.
            </p>
            <p>
              6.9. Продавец не гарантирует сохранность информации в случае
              блокировки устройства
            </p>
            <p>
              6.10. После полной оплаты договора устройство будет снято с
              системы защиты и коробка устройства будет возвращена Покупателю.
            </p>
            <p>
              6.11. В случае возврата Покупателем товара в связи с
              неспособностью исполнить свои обязательства по договору, Продавец
              производит перерасчет стоимости товара с учетом уплаченных
              Покупателем денежных средств и морального устаревания товара на
              день возврата. В этом случае соответствующая сторона покроет
              разницу в ценах.
            </p>
            <p>6.12. Вышеуказанные меры остаются на усмотрение Продавца.</p>
          </div>
        </div>

        {/* secend big Card */}
        <div className="container">
          <div className="halfCardMini">
            <p className="topTitle">7. ИТОГОВЫЕ УСЛОВИЯ</p>
            <p>7.1. Данный договор действует со дня его подписания.</p>
            <p>
              7.2. Настоящее соглашение действует до полного выполнения
              Покупателем своих обязательств.
            </p>
            <p>
              7.3. В случае изменения адреса и реквизитов стороны должны
              уведомить друг друга в письменной форме в течение 3 рабочих дней.
            </p>
            <div>
              <div className="allCard">
                <p className="topTitle2">
                  8. ЮРИДИЧЕСКИЕ АДРЕСА И РЕКВИЗИТЫ СТОРОН
                </p>
              </div>
              <div className="container2">
                <div className="halfCard">
                  <div className="halfCardMini">
                    <p className="centerTitle">Продавец: «iSTYLE PLUS» МЧЖ</p>
                    <p>
                      <b>Адрес:</b> Г. Ташкент, Юнусабадский район, Центр-6,
                      111д, 2-квартира.
                    </p>
                    <p>
                      <b>Банк:</b> АТБ «Ипотека-банк» Ташкентский филиал.
                    </p>
                    <p>
                      <b>МФО:</b> 00419
                    </p>
                    <p>
                      <b>X/p:</b> 20208000405725426001
                    </p>
                    <p>
                      <b>ИНН:</b> 310970893
                    </p>

                    <p>
                      <b>Директор</b>
                    </p>
                    <p>
                      <b>Шокиров Ш.З</b> __________________
                    </p>
                  </div>
                </div>

                {/* right */}
                <div className="halfCard">
                  <div className="halfCardMini">
                    <p className="centerTitle">Покупатель</p>
                    <p>
                      <b>Ф.И.О:</b> _________________________________________
                    </p>
                    <p>
                      <b>Серия и номер паспорта/ID: </b> ____________________
                    </p>
                    <p>
                      <b>Адрес:</b> _______________________________________
                      <br />
                      <br /> _______________________________________________
                    </p>
                    <p>
                      <b>Номер телефона:</b> _______________________________
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="containerTable">
              <p>
                Приложение №1 купли-продажи на основе рассрочки <br />
                "____"____________________2024г.
              </p>
              <div className="containerTable">
                <p className="tableTitle">ГРАФИК ПЛАТЕЖЕЙ</p>

                <table>
                  <thead>
                    <tr>
                      <th>№</th>
                      <th>Срок оплаты</th>
                      <th>Сумма оплаты (сум)</th>
                      <th>Для заметок</th>
                    </tr>
                  </thead>
                  <tbody>
                    {get(location, "state.tableData", []).map((v, i) => {
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>
                            {moment(
                              get(
                                dataLocation,
                                `DocumentInstallments[${i}].DueDate`,
                                "",
                              ),
                            ).format("MM.DD.YYYY")}
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                      );
                    })}

                    <tr>
                      <td colSpan={2}>
                        <b>Итого:</b>
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="container2">
                <div className="halfCard">
                  <div className="halfCardMini">
                    <b>
                      <p>Продавец «iSTYLE PLUS» МЧЖ</p>
                      <p>Директор</p>
                    </b>
                    <p>Шокиров Ш.З ___________________</p>
                  </div>
                </div>
                <div className="halfCard">
                  <div className="halfCardMini">
                    <b>Покупатель:</b>
                    <p>Ф.И.О _________________________________________</p>
                    <br />
                    <p>Подпись: _________________________________________</p>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DownloadStyle>
    </Layout>
  );
};

export default Download;
