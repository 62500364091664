import React, { useState, useCallback, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ErrorModal } from "../../components/Modal";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import { main } from "../../store/slices";
import LoginStyle from "./LoginStyle";
import { get } from "lodash";
import api, { customFuncsApi } from "../../api";

const Login = () => {
  const { setMe, setToken } = main.actions;
  const { info } = useSelector((state) => state.main);

  const loginValue = get(info, "login", "");
  const passwordValue = get(info, "password", "");

  const errorRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [login, setLogin] = useState(loginValue);
  const [password, setPassword] = useState(passwordValue);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (window.location.hostname.includes("localhost")) {
      setLogin("boshliq");
      setPassword("Sal0mTest");
    }
  }, []);

  const loginProfile = useCallback(() => {
    setIsLoading(true);

    customFuncsApi
      .post("/login", {
        UserName: login,
        Password: password,
      })
      .then((res) => {
        dispatch(setToken(get(res, "data.SessionId", "")));
        secure();
      })
      .catch((err) => {
        errorRef.current?.open("Неправильный логин или пароль.");
        console.log("err", err);
        setIsLoading(false);
      });
  }, [login, password]);

  const secure = () => {
    api
      .get(
        `EmployeesInfo?$expand=Department&$select=EmployeeID,LastName,FirstName,Department2,U_CashAccount,U_CardAccount,U_TransAccount,U_Warehouse&$filter=EmployeeCode eq '${login}' and ExternalEmployeeNumber eq '${password}'`,
      )
      .then((res) => {
        const resData = get(JSON.parse(res.data), "value[0]", {});
        console.log("res w", resData);
        if (get(resData, "EmployeeID", "")) {
          if (get(resData, "Department2.Name", "") === "Sotuv") {
            navigate("/allProducts");
          } else if (get(resData, "Department2.Name", "") === "Undiruv") {
            navigate("/recovery");
          } else {
            navigate("/allProducts");
          }
          dispatch(setMe(resData));
        } else {
          errorRef.current?.open("Неправильный логин или пароль.");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        errorRef.current?.open(
          get(JSON.parse(err.response.data), "error.message", ""),
        );
        setIsLoading(false);
      });
  };

  return (
    <>
      <LoginStyle>
        <div className="container">
          <div style={{ width: "100%" }}>
            <div className="card">
              <div>
                <label htmlFor="login" className="label">
                  Login
                </label>
              </div>
              <input
                type="text"
                id="login"
                placeholder="Login"
                value={login}
                className="input"
                onChange={(e) => setLogin(e.target.value)}
              />
            </div>
            <div className="card">
              <div>
                <label htmlFor="password" className="label">
                  Password
                </label>
              </div>
              <input
                type="text"
                id="password"
                placeholder="Password"
                defaultValue={passwordValue}
                value={password}
                className="input"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <div style={{ textAlign: "center" }}>
              <Button
                onClick={() => loginProfile()}
                children={"Вход"}
                disabled={login.length < 1 || password.length < 1}
                isLoading={isLoading}
                btnStyle={{ width: 100 }}
                hoverBtnStyle={{ width: 100 }}
              />
            </div>
          </div>
        </div>
      </LoginStyle>
      <ErrorModal getRef={(r) => (errorRef.current = r)} />
    </>
  );
};

export default Login;
