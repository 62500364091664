import React, { useEffect } from "react";
import LaunchStyle from "./LaunchStyle";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.png";

const Launch = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/login");
    }, 2000);
  }, []);

  return (
    <LaunchStyle>
      <div className="container">
        <p className={"logo"}>Welcome</p>
        {/*<img src={Logo} alt='Logo' className='logo' />*/}
      </div>
    </LaunchStyle>
  );
};

export default Launch;
