import React, { useEffect, useRef, useState } from "react";
import AllProductsStyle from "./AllProducts";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import { get } from "lodash";
import Layout from "../../components/Layout";
import { ErrorModal } from "../../components/Modal";
import ClipLoader from "react-spinners/ClipLoader";

const AllProducts = () => {
  const navigation = useNavigate();
  const errorRef = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [clientName, setClientName] = useState("");
  const [clientPhone, setClientPhone] = useState("");
  const [clientCode, setClientCode] = useState("");
  const [product, setProduct] = useState("");
  const [data, setData] = useState({
    data: [],
    currentPage: 0, //20
    nextPage: 0, //30
    oldPage: 0,
  });

  const search = (currentPage = 0, oldDataPage = 0) => {
    setIsLoading(true);
    api
      .post(
        `QueryService_PostQuery`,
        {
          QueryPath:
            "$crossjoin(Invoices,Invoices/DocumentLines,BusinessPartners)",
          QueryOption: `$expand=Invoices($select=DocEntry, DocNum, DocDate, CardName, CardCode),Invoices/DocumentLines($select=ItemDescription, LineTotal)&$filter=Invoices/DocEntry eq Invoices/DocumentLines/DocEntry and Invoices/CardCode eq BusinessPartners/CardCode and Invoices/DocumentStatus eq 'bost_Open' and Invoices/Cancelled eq 'tNO'
        ${
          clientPhone.length <= 0
            ? ""
            : `and contains(BusinessPartners/Phone1, '${clientPhone}')`
        } ${
          product.length <= 0
            ? ""
            : `and contains(Invoices/DocumentLines/ItemDescription, '${product}')`
        } ${
          clientName.length <= 0
            ? ""
            : `and contains(CardName, '${clientName}')`
        }  ${
          clientCode.length <= 0 ? "" : `and CardCode eq '${clientCode}'`
        }&$orderby=Invoices/DocDate desc&$skip=${currentPage}`,
        },
        {
          headers: {
            Prefer: "odata.maxpagesize=10",
          },
        },
      )
      .then((res) => {
        const resData = get(JSON.parse(res.data), "value", []);
        const nextPage = Number(
          get(JSON.parse(res.data), "@odata.nextLink", "skip=0").split(
            "skip=",
          )[1],
        );

        if (oldDataPage === 0) {
          setData({
            data: [...resData],
            currentPage: currentPage === 0 ? 0 : data.nextPage,
            nextPage: nextPage === 0 ? data.currentPage : nextPage,
            oldPage: nextPage === 0 ? data.currentPage : nextPage - 20,
          });
        } else {
          setData({
            data: [...resData],
            currentPage: data.nextPage - 20,
            nextPage: nextPage,
            oldPage: nextPage < data.currentPage ? nextPage : nextPage - 20,
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        errorRef.current?.open(
          get(JSON.parse(err.response.data), "error.message", ""),
        );
        setIsLoading(false);
      });
  };

  useEffect(() => {
    search(0, 0);
  }, []);

  const viewItem = (v) => {
    navigation("/viewItem", { state: v });
  };

  const newDatas = () => {
    if (data.nextPage > data.currentPage) {
      search(data.nextPage, 0);
    } else {
      alert("boshqa malumot yoq");
    }
  };

  const oldData = () => {
    if (data.oldPage < 0) {
      alert("boshqa malumot yoq");
    } else {
      search(data.oldPage, 1);
    }
  };

  return (
    <Layout>
      <AllProductsStyle>
        <div className="container">
          <div className="searchCard">
            <input
              type="text"
              className="input"
              placeholder="Имя Клиента"
              defaultValue={clientName}
              onChange={(v) => setClientName(v.target.value)}
            />
            <input
              type="text"
              className="input"
              placeholder="Телефон клиента"
              defaultValue={clientPhone}
              onChange={(v) => setClientPhone(v.target.value)}
            />
            <input
              type="text"
              className="input"
              placeholder="Код клиента"
              defaultValue={clientCode}
              onChange={(v) => setClientCode(v.target.value)}
            />
            <input
              type="text"
              className="input"
              placeholder="Товар"
              defaultValue={product}
              onChange={(v) => setProduct(v.target.value)}
            />
            <Button onClick={() => search(0, 0)} isLoading={isLoading}>
              Поиск
            </Button>
          </div>
          <p className="productTitle">Продукты</p>

          {isLoading ? (
            <ClipLoader
              loading={true}
              size={75}
              aria-label="Loading Spinner"
              className={"loader"}
              data-testid="loader"
            />
          ) : (
            <>
              <table className="table">
                <thead>
                  <tr>
                    <th>Код клиента</th>
                    <th>Имя Клиента</th>
                    <th>Товар</th>
                    <th>Деньги</th>
                    <th>Дата продажи</th>
                  </tr>
                </thead>
                <tbody>
                  {data.data.map((v, i) => {
                    return (
                      <tr key={i} onClick={() => viewItem(v)} className="row">
                        <td>{get(v, "Invoices.CardCode", "Код покупателя")}</td>
                        <td>{get(v, "Invoices.CardName", "Имя покупателя")}</td>
                        <td>
                          {get(v, "Invoices/DocumentLines.ItemDescription", "")}
                        </td>
                        <td>
                          {" "}
                          {get(v, "Invoices/DocumentLines.LineTotal", "")} USD
                        </td>
                        <td>{get(v, "Invoices.DocDate", "")}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <div className="topCard">
                <div className="flex">
                  <Button
                    className={"btn"}
                    onClick={oldData}
                    btnStyle={{ marginRight: 10 }}
                  >
                    {"<"}
                  </Button>
                  <Button className={"btn"} onClick={newDatas}>
                    {">"}
                  </Button>
                </div>
                <Button
                  className={"btn"}
                  onClick={() => navigation("/createProducts")}
                >
                  {"+"}
                </Button>
              </div>
            </>
          )}
        </div>
      </AllProductsStyle>
      <>
        <ErrorModal
          getRef={(r) => {
            errorRef.current = r;
          }}
        />
      </>
    </Layout>
  );
};

export default AllProducts;
