import React from "react";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import DrawerMenuStyle from "./DrawerMenuStyle";
import { useNavigate } from "react-router-dom";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { get } from "lodash";
import { useSelector } from "react-redux";

const DrawerMenu = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState(false);

  const { getMe } = useSelector((state) => state.main);

  const data =
    get(getMe, "Department2.Name", "") === "Sotuv"
      ? [
          {
            title: "Продажи",
            navigate: "allProducts",
          },
          {
            title: "Курс доллара",
            navigate: "dollarCourse",
          },
          {
            title: "Клиенты",
            navigate: "clients",
          },
          {
            title: "Выход",
            navigate: "login",
          },
        ]
      : get(getMe, "Department2.Name", "") === "Undiruv"
        ? [
            {
              title: "Сборы",
              navigate: "recovery",
            },
            {
              title: "Отчет о сборе",
              navigate: "recoveryChart",
            },
            {
              title: "Курс доллара",
              navigate: "dollarCourse",
            },
            {
              title: "Клиенты",
              navigate: "clients",
            },
            {
              title: "Выход",
              navigate: "login",
            },
          ]
        : [
            {
              title: "Продажи",
              navigate: "allProducts",
            },
            {
              title: "Сборы",
              navigate: "recovery",
            },
            {
              title: "Отчет о сборе",
              navigate: "recoveryChart",
            },
            {
              title: "Курс доллара",
              navigate: "dollarCourse",
            },
            {
              title: "Клиенты",
              navigate: "clients",
            },
            {
              title: "Выход",
              navigate: "login",
            },
          ];

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const goToScreen = (n) => {
    if (n === "login") {
      navigate(`/${n}`, { replace: true });
    } else {
      navigate(`/${n}`);
    }
  };

  return (
    <DrawerMenuStyle>
      <button onClick={toggleDrawer} className="listBtn">
        <AiOutlineUnorderedList />
      </button>
      <Drawer open={isOpen} onClose={toggleDrawer} direction="left">
        <div className="drawer">
          <p className="menuTitle">Меню</p>

          {data.map((v, i) => {
            return (
              <button
                key={i}
                className={v.isActive ? "btnActive" : "btn"}
                onClick={() => goToScreen(v.navigate)}
              >
                {v.title}
              </button>
            );
          })}
        </div>
      </Drawer>
    </DrawerMenuStyle>
  );
};

export default DrawerMenu;
