import React, { useEffect, useRef, useState } from "react";
import { SuccessModal, PaymentModal, ErrorModal } from "../../components/Modal";
import ViewRecoveryItemStyle from "./ViewRecoveryItemStyle";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Layout from "../../components/Layout";
import { useSelector } from "react-redux";
import { get } from "lodash";
import api, { customFuncsApi } from "../../api";
import { AiOutlineSend } from "react-icons/ai";
import { isEmpty } from "lodash/lang";

const ViewRecoveryItem = () => {
  const { getMe } = useSelector((state) => state.main);

  const navigate = useNavigate();
  const location = useLocation();

  const successRef = useRef();
  const errorRef = useRef();
  const paymentRef = useRef();

  const executer = get(location, "state.executer", []);
  const DocEntry = get(location, "state.data.DocEntry", 0);
  const InstlmntID = get(location, "state.data.InstlmntID", 0);

  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const [employeeID, setEmployeeID] = useState("");

  const [isCommitLoading, setIsCommitLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    getAllData();
    getComments();
  }, []);

  const getAllData = async () => {
    await customFuncsApi
      .get(`installment?DocEntry=${DocEntry}&InstlmntID=${InstlmntID}`)
      .then((res) => {
        console.log(res.data);
        setData(get(res, "data", {}));
        setEmployeeID(get(res, "data.U_Employee", 0));
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          navigate("/login", { replace: true });
        }
        errorRef.current?.open(
          get(JSON.parse(err.response.data), "error.message", ""),
        );
      });
  };
  const getComments = () => {
    api
      .get(
        `U_COMMENTS?$filter=U_Invoice eq ${DocEntry} and U_InstlmntID eq ${InstlmntID}`,
      )
      .then((res) => {
        setComments(get(JSON.parse(res.data), "value", []));
      })
      .catch((err) => {
        errorRef.current?.open(
          get(JSON.parse(err.response.data), "error.message", ""),
        );
      });
  };

  const deleteComments = (id) => {
    api
      .delete(`U_COMMENTS(${id})`)
      .then((res) => getComments())
      .catch((err) => {
        errorRef.current?.open(
          get(JSON.parse(err.response.data), "error.message", ""),
        );
      });
  };

  const sendComment = () => {
    setComment("");
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    const body = {
      U_Employee: get(getMe, "EmployeeID", ""),
      U_CreateDate: `${year}-${month}-${day}`,
      U_Invoice: get(data, "DocEntry", ""),
      U_InstlmntID: InstlmntID,
      U_Text: comment,
    };
    api
      .post("U_COMMENTS", body)
      .then((res) => getComments())
      .catch((err) => {
        errorRef.current?.open(
          get(JSON.parse(err.response.data), "error.message", ""),
        );
      });
  };

  const createPayment = (sum, typeOfPayment, valuta, courseDollar) => {
    setIsLoading(true);

    api
      .post(`IncomingPayments`, {
        CardCode: get(data, "CardCode", 0),
        CashSum: Number(sum),
        CashAccount: getMe[typeOfPayment],
        BankChargeAmount: 0,
        DocCurrency: valuta,
        PaymentInvoices: [
          {
            DocEntry,
            InstallmentId: get(data, "InstlmntID", 0),
            SumApplied:
              valuta === "UZS"
                ? Number(sum) / Number(courseDollar)
                : Number(sum),
          },
        ],
      })
      .then(() => {
        successRef.current?.open("Платеж успешно создан");
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        errorRef.current?.open(
          get(JSON.parse(err.response.data), "error.message", ""),
        );
      });
  };

  const cancel = () => {
    navigate("/recovery");
  };

  const allSaves = () => {
    setIsCommitLoading(true);
    api
      .patch(`Invoices(${get(data, "DocEntry", 0)})`, {
        DocumentInstallments: [
          {
            InstallmentId: get(data, "InstlmntID", 0),
            U_Comment: comment,
            U_Employee: employeeID > 0 ? employeeID : null,
          },
        ],
      })
      .then(() => {
        successRef.current?.open("Заказ успешно сохранен");
        setIsCommitLoading(false);
      })
      .catch((err) => {
        setIsCommitLoading(false);
        errorRef.current?.open(
          get(JSON.parse(err.response.data), "error.message", ""),
        );
      });
  };

  return (
    <Layout>
      <ViewRecoveryItemStyle>
        <div className="container">
          <div className="betweenCard">
            <p className="fio">Код</p>
            <input
              type="text"
              className="inputDefault"
              value={get(data, "DocNum", "Kod")}
              disabled={true}
            />
            <p className="fio">ФИО</p>
            <input
              type="text"
              className="inputDefault"
              value={get(data, "CardName", "Клиент")}
              disabled={true}
            />
            <p className="fio">Продукт</p>
            <input
              type="text"
              className="inputDefault"
              value={get(data, "Dscription", "Товар")}
              disabled={true}
            />
            <p className="fio">Тел.</p>
            <input
              type="text"
              className="inputDefault"
              value={get(data, "Phone1", "Tel")}
              disabled={true}
            />

            <p className="fio">Оплачено за этот месяц</p>
            <input
              type="text"
              className="inputDefault"
              value={get(data, "INV6.PaidToDate", "0")}
              disabled={true}
            />
            <Button btnStyle={{ backgroundColor: "red" }} onClick={cancel}>
              Назад
            </Button>
            {get(getMe, "Department2.Name", "") === "Boshqaruv" ? (
              <Button
                isLoading={isLoading}
                btnStyle={{ marginLeft: 10, width: 150 }}
                hoverBtnStyle={{ marginLeft: 10, width: 150 }}
                onClick={() => paymentRef.current?.open()}
              >
                Платить
              </Button>
            ) : null}
          </div>

          <div className="betweenCard">
            <p className="fio">Общий долг клиента</p>
            <input
              type="text"
              className="inputDefault"
              value={get(data, "Balance", "Balance")}
              disabled={true}
            />
            <p className="fio">Цена</p>
            <input
              type="number"
              placeholder="Текущая цена"
              className="inputDefault"
              value={get(data, "DocTotal", "Текущая цена не найден")}
              disabled={true}
            />
            <p className="fio">Заплатил за эту покупку</p>
            <input
              type="number"
              placeholder="Цена"
              className="inputDefault"
              value={get(data, "PaidToDate", "Цена не найден")}
              disabled={true}
            />
            <p className="fio">Должен заплатить в этом месяце</p>
            <input
              type="number"
              placeholder="Цена"
              className="inputDefault"
              value={get(data, "InsTotal", "Цена не найден")}
              disabled={true}
            />
            <p className="fio">ИМEИ</p>
            <input
              placeholder="IntrSerial"
              className="inputDefault"
              value={get(data, "IntrSerial", "IntrSerial не найден")}
              disabled={true}
            />
            {/*<p className={"comment"}>Комментарии</p>*/}
            <p className="fio">Комментарий</p>
            <div className={"commentSection"}>
              <textarea
                name="comment"
                // id="comment"
                className="input1"
                // cols="30"
                rows="1"
                value={comment}
                onChange={(v) => setComment(v.target.value)}
              />
              <button
                className={"sendComment"}
                onClick={() => sendComment()}
                disabled={isEmpty(comment)}
              >
                <AiOutlineSend />
              </button>
            </div>
            {comments.map((v, i) => {
              return (
                <>
                  <p className="fio">{get(v, "U_CreateDate", "")}</p>
                  <div className="inputCard2">
                    <input
                      type="text"
                      className="inputDefault2"
                      value={get(v, "U_Text", "")}
                      disabled={true}
                    />
                    <button onClick={() => deleteComments(get(v, "Code", ""))}>
                      x
                    </button>
                  </div>
                </>
              );
            })}
          </div>

          <div className="betweenCard">
            <p className="fio">Дата платежа</p>
            <input
              type="string"
              placeholder="Цена"
              className="inputDefault"
              defaultValue={get(data, "DueDate", "Sana").slice(0, 10)}
              disabled={true}
            />
            <p className="fio">Количество месяцев</p>
            <input
              type="number"
              placeholder="Цена"
              className="inputDefault"
              value={get(data, "Installmnt", 1) - 1}
              disabled={true}
            />

            <p className="fio">Текущий месяц</p>
            <input
              type="number"
              placeholder="Цена"
              className="inputDefault"
              value={get(data, "InstlmntID", 1) - 1}
              disabled={true}
            />

            <p className="fio">Сменить приемник</p>
            <select
              name="executer"
              id="executer"
              className="input"
              onChange={(v) => {
                setEmployeeID(v.target.value);
              }}
              defaultValue={"s"}
            >
              <option value={""} selected={get(data, "U_Employee", "") === 0}>
                {""}
              </option>
              {executer.map((v, i) => {
                return (
                  <option
                    value={get(v, "EmployeesInfo.EmployeeID", 0)}
                    key={i}
                    selected={
                      v.EmployeesInfo.EmployeeID === get(data, "U_Employee", "")
                    }
                  >
                    {get(v, "EmployeesInfo.FirstName", "Name")}{" "}
                    {get(v, "EmployeesInfo.LastName", "Surename")}
                  </option>
                );
              })}
            </select>

            <Button
              btnStyle={{ width: 250 }}
              hoverBtnStyle={{ width: 250 }}
              onClick={allSaves}
              isLoading={isCommitLoading}
            >
              Сохранять
            </Button>
          </div>
        </div>
      </ViewRecoveryItemStyle>
      <>
        <ErrorModal getRef={(r) => (errorRef.current = r)} />
        <PaymentModal
          getRef={(r) => {
            paymentRef.current = r;
          }}
          onConfirm={(sum, typeOfPayment, valuta, courseDollar) =>
            createPayment(sum, typeOfPayment, valuta, courseDollar)
          }
        />
        <SuccessModal
          getRef={(r) => {
            successRef.current = r;
          }}
        />
      </>
    </Layout>
  );
};

export default ViewRecoveryItem;
