import React, { memo, useEffect, useRef, useState } from "react";
import UpdateUserStyle from "./UpdateUserStyle";
import Modal from "react-modal";
import { get } from "lodash";
import api from "../../../api";
import { ErrorModal } from "../../../components/Modal";
import { AiOutlineReload } from "react-icons/ai";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const UpdateUserModal = (props) => {
  const { getRef = () => {}, onUpdated = () => {}, onClose = () => {} } = props;
  const errorRef = useRef();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [cardCode, setCardCode] = useState("");
  const [consumer, setConsumer] = useState("");
  const [passport, setPassport] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [gender, setGender] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const ref = {
      open: (data) => {
        setIsOpenModal(true);
        console.log("user details", JSON.stringify(data, null, 2));
        setCardCode(
          get(data, "CardCode", null) !== null ? get(data, "CardCode", "") : ""
        );
        setConsumer(
          get(data, "CardName", null) !== null ? get(data, "CardName", "") : ""
        );
        setAddress(
          get(data, "Address", null) !== null ? get(data, "Address", "") : ""
        );
        setPassport(
          get(data, "U_PS", null) !== null ? get(data, "U_PS", "") : ""
        );
        setPhone(
          get(data, "Phone1", null) !== null ? get(data, "Phone1", "") : ""
        );
        setGender(
          get(data, "U_Gender", null) !== null ? get(data, "U_Gender", "") : ""
        );
      },
      close: () => setIsOpenModal(false),
    };
    getRef(ref);
  }, []);

  const submit = () => {
    setIsLoading(true);
    let url = `BusinessPartners('${cardCode}')`;
    const body = {
      Phone1: phone,
      CardName: consumer,
      Address: address,
      U_PS: passport,
      U_Gender: gender,
    };
    api
      .patch(url, body)
      .then(() => {
        onUpdated();
        setIsOpenModal(false);
      })
      .catch((err) => {
        errorRef.current?.open(
          get(JSON.parse(err.response.data), "error.message", "")
        );
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Modal
      isOpen={isOpenModal}
      onRequestClose={() => setIsOpenModal(false)}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <UpdateUserStyle>
        <div className="card">
          <h2>{"Обновите информацию"}</h2>
          <input
            type="text"
            placeholder="Mijoz"
            className="input"
            defaultValue={consumer}
            onChange={(v) => setConsumer(v.target.value)}
          />
          <input
            type="text"
            placeholder="Passport"
            className="input"
            value={passport}
            onChange={(v) => setPassport(v.target.value)}
          />
          <input
            type="text"
            placeholder="Address"
            className="input"
            defaultValue={address}
            onChange={(v) => setAddress(v.target.value)}
          />
          <input
            // type="number"
            placeholder="Phone"
            className="input"
            defaultValue={phone}
            onChange={(v) => setPhone(v.target.value)}
          />
          <input
            defaultChecked={gender === "Male"}
            type="radio"
            id="Male"
            name="fav_language"
            value="Male"
            onChange={(v) => setGender(v.target.value)}
          />
          <label htmlFor="Male">Мужчина</label>
          <input
            defaultChecked={gender === "Female"}
            type="radio"
            id="Female"
            name="fav_language"
            value="Female"
            onChange={(v) => setGender(v.target.value)}
          />
          <label htmlFor="Female">Женщина</label>
          <div className="centerCard">
            <button className="btnN" onClick={() => setIsOpenModal(false)}>
              {"Нет"}
            </button>
            <button
              className="btnY"
              disabled={cardCode.length <= 0 || consumer.length <= 0}
              onClick={submit}
            >
              {!isLoading ? "Да" : <AiOutlineReload size={"20"} />}
            </button>
          </div>
        </div>
      </UpdateUserStyle>
      <ErrorModal getRef={(r) => (errorRef.current = r)} />
    </Modal>
  );
};

export default memo(UpdateUserModal);
