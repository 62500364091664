import React, { useEffect, useRef, useState } from "react";
import ClientsStyle from "./Clients";
import Button from "../../components/Button";
import api from "../../api";
import { get } from "lodash";
import Layout from "../../components/Layout";
import { ErrorModal } from "../../components/Modal";
import { AiOutlineReload } from "react-icons/ai";
import UpdateUser from "./UpdateUser";
import ClipLoader from "react-spinners/ClipLoader";

const Clients = () => {
  const errorRef = useRef();
  const updateRef = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [clientName, setClientName] = useState("");
  const [clientPhone, setClientPhone] = useState("");
  const [clientCode, setClientCode] = useState("");
  const [data, setData] = useState({
    data: [],
    currentPage: 0, //20
    nextPage: 0, //30
    oldPage: 0,
  });

  const search = (currentPage = 0, oldDataPage = 0) => {
    setIsLoading(true);
    let url =
      "BusinessPartners?$select=CardCode,CardName,Address,Phone1,U_Gender,U_PS&$orderby=CardName asc";
    if (
      clientCode.length >= 1 ||
      clientPhone.length >= 1 ||
      clientName.length >= 1
    )
      url += "&$filter=CardType eq 'cCustomer'";
    if (clientName.length >= 1)
      url += ` and contains(CardName, '${clientName}')`;
    if (clientCode.length >= 1) url += ` and contains(U_PS, '${clientCode}')`;
    if (clientPhone.length >= 1)
      url += ` and contains(Phone1, '${clientPhone}')`;
    url += `&$skip=${currentPage}`;
    api
      .get(url, { headers: { Prefer: "odata.maxpagesize=20" } })
      .then((res) => {
        const resData = get(JSON.parse(res.data), "value", []);
        const nextPage = Number(
          get(JSON.parse(res.data), "@odata.nextLink", "skip=0").split(
            "skip=",
          )[1],
        );
        if (oldDataPage === 0) {
          setData({
            data: [...resData],
            currentPage: currentPage === 0 ? 0 : data.nextPage,
            nextPage: nextPage === 0 ? data.currentPage : nextPage,
            oldPage: nextPage === 0 ? data.currentPage : nextPage - 20,
          });
        } else {
          setData({
            data: [...resData],
            currentPage: data.nextPage - 20,
            nextPage: nextPage,
            oldPage: nextPage < data.currentPage ? nextPage : nextPage - 20,
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        errorRef.current?.open(
          get(JSON.parse(err.response.data), "error.message", ""),
        );
        setIsLoading(false);
      });
  };

  useEffect(() => {
    search(0, 0);
  }, []);

  const viewItem = (v) => {
    updateRef.current?.open(v);
  };

  const newDatas = () => {
    if (data.nextPage > data.currentPage) {
      search(data.nextPage, 0);
    } else {
      alert("boshqa malumot yoq");
    }
  };

  const oldData = () => {
    if (data.oldPage < 0) {
      alert("boshqa malumot yoq");
    } else {
      search(data.oldPage, 1);
    }
  };

  return (
    <Layout>
      <ClientsStyle>
        <div className="container">
          <div className="searchCard">
            <input
              type="text"
              className="input"
              placeholder="Имя Клиента"
              defaultValue={clientName}
              onChange={(v) => setClientName(v.target.value)}
            />
            <input
              type="text"
              className="input"
              placeholder="Телефон клиента"
              defaultValue={clientPhone}
              onChange={(v) => setClientPhone(v.target.value)}
            />
            <input
              type="text"
              className="input"
              placeholder="Код клиента"
              defaultValue={clientCode}
              onChange={(v) => setClientCode(v.target.value)}
            />
            <Button onClick={() => search(0, 0)} isLoading={isLoading}>
              Поиск
            </Button>
          </div>
          <p className="productTitle">Клиенты</p>
          {isLoading ? (
            <ClipLoader
              loading={true}
              size={75}
              aria-label="Loading Spinner"
              className={"loader"}
              data-testid="loader"
            />
          ) : (
            <>
              <table className="table">
                <thead>
                  <tr>
                    <th>Код клиента</th>
                    <th>Имя Клиента</th>
                    <th>Телефон</th>
                    <th>Код клиента</th>
                    <th>Адрес</th>
                  </tr>
                </thead>
                <tbody>
                  {data.data.map((v, i) => {
                    return (
                      <tr key={i} onClick={() => viewItem(v)} className="row">
                        <td>{get(v, "CardCode", "")}</td>
                        <td>{get(v, "CardName", "")}</td>
                        <td>{get(v, "Phone1", "")}</td>
                        <td>{get(v, "U_PS", "")}</td>
                        <td>{get(v, "Address", "")}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="topCard">
                <div className="flex">
                  <Button
                    className={"btn"}
                    onClick={oldData}
                    btnStyle={{ marginRight: 10 }}
                  >
                    {"<"}
                  </Button>
                  <Button className={"btn"} onClick={newDatas}>
                    {">"}
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      </ClientsStyle>
      <ErrorModal getRef={(r) => (errorRef.current = r)} />
      <UpdateUser
        getRef={(ref) => (updateRef.current = ref)}
        onUpdated={() => search(data.currentPage, data.data)}
      />
    </Layout>
  );
};

export default Clients;
